<template>
  <div class="">
     <ul class="productlist">
      <li>
        <div class="left" @click="goproductinfoEngine">
          <img src="../../assets/image/product/picone.png" alt="">
        </div>
        <div class="right">
          <div class="center">
            <div class="icon"><b>01</b></div>
            <h2>黑洞实时渲染引擎</h2>
            <p class="alignLeft">BlackHole Engine是秉匠科技自主研发的，具备国际领先水平的BIM+GIS轻量化渲染引擎。引擎采用全新的三维渲染技术，可轻松管理多源异构、超大场景的模型数据，并提供精确的空间分析计算能力。</p>
          </div>
        </div>
      </li>
       <li>
         <div class="right">
          <div class="center">
            <div class="icon"><b>02</b></div>
            <h2>容智能建造管理平台</h2>
            <p class="alignLeft">平台基于数据结构化、管理可视化的理念，结合模型的轻量化技术和实时渲染技术，借助于云+端的模式，实现工程建设期信息的可视化、标准化及协同共享。</p>
          </div>
        </div>
        <div class="left" @click="goproductinfoPlatform">
          <img src="../../assets/image/product/picthree.png" alt="">
        </div>
      </li>
      <li>
        <div class="left">
          <img src="../../assets/image/product/pictwo.png" alt="">
        </div>
        <div class="right">
          <div class="center">
            <div class="icon"><b>03</b></div>
            <h2>智慧运维管理平台</h2>
            <p class="alignLeft">以BlackHole三维实时渲染引擎为基础，以BIM技术为核心，融合IBMS、物联网、人工智能、大数据等技术,对建筑物实现可视化、智慧化的设施设备管理系统（FM）。</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    goproductinfoEngine () {
      this.$router.push('productinfoEngine')
    },
    goproductinfoPlatform () {
      this.$router.push('productinfoPlatform')
    }
  }
}
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
.productlist{
    max-width: 1200px;
    margin: 120px auto 180px;
    overflow: hidden;
    li{
      display: flex;
      margin-top: 40px;
      .left{
        width: 516px;
        height: 410px;
      }
      .right{
        flex: 1;
        margin-left: 50px;
        position: relative;
        .center{
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        .icon{
          width:68px;
          height:68px;
          border:2px solid rgba(102,102,102,1);
          opacity:0.47;
          border-radius:11px;
          padding: 5px 0;
          position: relative;
          b{
            color: #3475BB;
            display: block;
            font-size: 26px;
            opacity: 1;
            font-weight: 700;
            line-height: 54px;
            position: absolute;
            left: -5px;
            top: 5px;
            background-color: #fff;
          }
        }
        h2{
          margin: 30px 0px 20px 0px;
        }
        p{
          display: block;
          // width: 403px;
        }
      }
    }
    li:first-child{
      margin: 0;
    }
    li:nth-child(2){
      padding-left: 5px;
      .right{
        margin-left: 0;
        margin-right: 50px;
      }
    }
  }
</style>
